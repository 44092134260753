import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/reviewPage.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ReviewHeader = makeShortcode("ReviewHeader");
const ReviewFooter = makeShortcode("ReviewFooter");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ReviewHeader data={props.pageContext.frontmatter} mdxType="ReviewHeader" />
    <h1>{`Overall`}</h1>
    <p>{`If you're a quality snob this spot's for you. Their fresh local ingredients in their compostable
bowls make our "Seattle-Mom" heart's sing. However, our teenage boy tummy's were left grumby, especially
considering this is one of the more expensive joints in town.
That's not to say most people wouldn't be full because they would, but poke monsters like
us have a high bar. All in all, the quality of the pieces were great but not up to snuff
when it comes to inventiveness. `}<strong parentName="p">{`#basic`}</strong></p>
    <p><em parentName="p">{`Disclaimer: no seattle-moms are writing this blog.`}</em></p>
    <h1>{`Key Points:`}</h1>
    <ul>
      <li parentName="ul">{`Got extra ginger, but it felt more awkward than it was worth.`}</li>
      <li parentName="ul">{`Loved the quality fish and options are great, but they are a wee bit stingy.`}</li>
      <li parentName="ul">{`I got hit with a ball of al dente rice. `}</li>
      <li parentName="ul">{`High quality toppings, but ya basic.`}</li>
      <li parentName="ul">{`Suggestion: try the spicy crab salad. It different 🤔`}</li>
      <li parentName="ul">{`Minimal sauce options.`}</li>
    </ul>
    <h1>{`Biggest Suggestion`}</h1>
    <p>{`Up your quantity to cost ratio.`}</p>
    <ReviewFooter data={props.pageContext.frontmatter} mdxType="ReviewFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      